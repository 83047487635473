import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

interface SuccessModalProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
}

const InfoModal: React.FC<SuccessModalProps> = ({
  onClose,
  isOpen,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            <Icon as={InfoIcon} boxSize={8} color="blue.500" />
            <Text textAlign="center">{message}</Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button colorScheme="blue" onClick={onClose}>
              {t("close")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
