// ConfirmationModal.tsx

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Flex,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { Item } from "../index";
import { formatCurrency } from "../../../../lib/utilities";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  isOpen: boolean;
  items: Item[];
  totalAmount: number;
  payNowAmount: number;
  fee: number;
  flexPayAmount: number;
  supplier: string;
  dueDate: string;
  currency: string;
  onClose: () => void;
  onConfirm: () => void;
  lateFeeAmount: number;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  dueDate,
  items,
  fee,
  totalAmount,
  payNowAmount,
  flexPayAmount,
  supplier,
  currency,
  onClose,
  onConfirm,
  lateFeeAmount,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("review_order")}</ModalHeader>
        <ModalBody>
          <VStack align="start" spacing={2} minW={200}>
            <Stack spacing={1} width="100%">
              <Flex justifyContent="space-between">
                <Text width="100%">
                  {t("supplier")}: {supplier}
                </Text>
              </Flex>
            </Stack>
            {items.map((item, index) => (
              <Text key={index}>
                - {item.name}: {formatCurrency(item.quantity)} * {currency}{" "}
                {formatCurrency(item.unitPrice)} = {currency}{" "}
                {formatCurrency(item.total)}
              </Text>
            ))}
            <Stack spacing={1} width="100%">
              <Text fontWeight="bold">{t("payment_details")}</Text>

              <Flex justifyContent="space-between">
                <Text width="50%">{t("total_amount")} </Text>
                <Text width="50%">
                  {currency} {formatCurrency(totalAmount + fee)}
                </Text>
              </Flex>
              {/* {flexPayAmount <= 0 && lateFeeAmount > 0 && (
                <Flex justifyContent="space-between">
                  <Text width="50%">Late fee</Text>
                  <Text width="50%">
                    {currency} {formatCurrency(lateFeeAmount)}
                  </Text>
                </Flex>
              )} */}
              {flexPayAmount > 0 && (
                <>
                  <Flex justifyContent="space-between">
                    <Text width="50%">{t("pay_now")}</Text>
                    <Text width="50%">
                      {currency} {formatCurrency(payNowAmount)}
                    </Text>
                  </Flex>
                  <Text fontWeight="bold">
                    {t("flex_pay_amount_to_pay_later")}
                  </Text>
                  <Flex justifyContent="space-between">
                    <Text width="50%">{t("amount")}</Text>
                    <Text width="50%">
                      {currency} {formatCurrency(flexPayAmount)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text width="50%">{t("transaction_fee")}</Text>
                    <Text width="50%">
                      {currency} {formatCurrency(fee)}
                    </Text>
                  </Flex>
                  {/* {lateFeeAmount > 0 && (
                    <Flex justifyContent="space-between">
                      <Text width="50%">Late fee</Text>
                      <Text width="50%">
                        {currency} {formatCurrency(lateFeeAmount)}
                      </Text>
                    </Flex>
                  )} */}

                  <Flex justifyContent="space-between">
                    <Text width="50%">{t("total_amount_to_repay")}</Text>
                    <Text width="50%">
                      {currency} {formatCurrency(flexPayAmount + fee)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text width="50%">{t("due_date")}</Text>
                    <Text width="50%">{dueDate}</Text>
                  </Flex>
                </>
              )}
            </Stack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              setIsLoading(true);
              await onConfirm();
              setIsLoading(false);
            }}
            isLoading={isLoading}
          >
            {t("submit")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
